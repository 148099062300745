<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          {{ title }}
        </h5>

        <b-form-group id="input-group-clinic-name">
          <label for="input-address"
            >Nama Klinik <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-clinic-name"
            v-model="form.clinic_name"
            placeholder="Nama Kllinik"
          ></b-form-input>
          <small class="text-danger">{{ error.clinic_name }}</small>
        </b-form-group>

        <label for="input-address"
          >Subdomain Klinik <span class="text-danger">*</span></label
        >
        <b-input-group class="mb-5">
          <template #append>
            <b-input-group-text
              ><span class="font-weight-bold">.medhop.id</span></b-input-group-text
            >
          </template>
          <b-form-input
            id="input-clinic-subdomain"
            v-model="form.clinic_subdomain"
            placeholder="Subdomain"
            :disabled="form.status == 0 ? false : true"
          ></b-form-input>
        </b-input-group>
          <small class="text-danger">{{ error.clinic_subdomain }}</small>

        <b-form-group id="input-group-clinic-name">
          <label for="input-address"
            >Nama Pendaftar <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-clinic-name"
            v-model="form.name"
            placeholder="Nama Pendaftar"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <div class="row">
          <div class="col">
            <b-form-group id="input-group-clinic-email">
              <label for="input-address"
                >Email Pendaftar <span class="text-danger">*</span></label
              >
              <b-form-input
                id="input-clinic-email"
                v-model="form.email"
                placeholder="Email Pendaftar"
              ></b-form-input>
              <small class="text-danger">{{ error.email }}</small>
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group id="input-group-phone">
              <label for="input-address"
                >Nomor Whatsapp <span class="text-danger">*</span></label
              >
              <b-form-input
                id="input-phone"
                v-model="form.phone"
                placeholder="Nomor Whatsapp"
              ></b-form-input>
              <small class="text-danger">{{ error.phone }}</small>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="input-group-province">
          <label for="input-address">Provinsi: <span class="text-danger">*</span></label>
          <treeselect
            v-model="form.province_id"
            :options="region"
            placeholder="Pilih Provinsi"
            @input="setRegion"
            required
          />
          <small class="text-danger">{{ error.province }}</small>
        </b-form-group>

        <b-form-group id="input-group-capital" v-if="isRegion">
          <label for="input-address">Kota/kabupaten: <span class="text-danger">*</span></label>
          <treeselect
            v-model="form.city_id"
            :options="regionChild"
            placeholder="Pilih Kota/Kabupaten"
            required
          />
          <small class="text-danger">{{ error.city_id }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/registrants/')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    purpose: String,
    title: String,
  },
  data() {
    return {
      error: {
        clinic_name: "",
        clinic_subdomain: "",
        name: "",
        email: "",
        phone: "",
        province_id: "",
        city_id: "",
        description: "",
      },
      region: [],
      regionChild: [],
      // other
      isRegion: false,
      passwordIsVisible: "password",
    };
  },
  methods: {
    async getRegionOptions() {
      let response = await module.setTreeSelect("regions");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.region = response.data;
      }

      if (this.form.province_id != null) {
        this.isRegion = true;
        this.getRegionChildOptions(this.form.province_id);
      }
    },

    async getRegionChildOptions(id) {
      let response = await module.setTreeSelect("region/" + id);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.regionChild = response.data;
      }

      return true;
    },

    setRegion(data) {
      let status = this.getRegionChildOptions(data);

      if (status) {
        this.isRegion = true;
      }
    },
    async formOnsubmit() {

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push('/registrants/')
      }
    },
  },
  mounted() {
    this.getRegionOptions();
  },
};
</script>

<style scoped>
</style>